import React from "react";
import { Link } from "gatsby";
import { Row, Col, Container } from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";

const JobOpenings = () => {
  return (
    <>
      <PageWrapper
        headerConfig={{
          align: "right",
          button: "account", // cta, account, null
        }}
      >
        <div className="inner-banner pt-29 pt-lg-30 pb-9 pb-lg-12 bg-default-6">
          <Container>
            <Row className="justify-content-center pt-5">
              <Col xl="8" lg="9">
                <div className="px-md-15 text-center">
                  <h2 className="title gr-text-2 mb-8 mb-lg-10 heading-color">
                  VMWARE Enablement Specialist
                  </h2>
                  <p className="gr-text-7 mb-0 mb-lg-13 text-color-opacity"> Full Time</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="main-block pb-6 pb-lg-17 bg-default-6">
          <Container>
            <Row className="justify-content-center">
              <Col xl="8" lg="9">
                <div className="single-block mb-12 mb-lg-15">
                  <p className="gr-text-9 text-color-opacity mb-9">
                    AIN Technologies is a dynamic and innovative technology solutions provider committed to empowering businesses with cutting-edge VMware solutions. 
                    We are currently seeking a skilled and experienced VMware Enablement Specialist to join our team. 
                    This role offers the opportunity to work with leading-edge technologies and contribute to the growth and success of our clientele.
                  </p>
                </div>
                <div className="single-block mb-12 mb-lg-15">
                  <h3 className="gr-text-6 heading-color fw-bold mb-9">
                    What you’ll be doing
                  </h3>
                  <p className="gr-text-9 text-color-opacity mb-0">
                  As a VMware Enablement Specialist, your focus is on delivering impactful training sessions for both on-premises and cloud solutions. 
                  You'll lead the development of training infrastructure, install solutions in client environments, and facilitate the transfer of technology. 
                  Your role involves designing educational simulations, constructing course materials, and applying creative solutions with current VMware products. 
                  Additionally, you contribute to troubleshooting, mentoring, and maintaining a robust analytics infrastructure, showcasing adaptability and collaboration in the dynamic realm of VMware technologies.
                  </p>
                </div>
                <div className="single-block mb-12 mb-lg-14">
                  <h3 className="gr-text-6 heading-color fw-bold pb-3">
                    Skills &amp; Qualifications
                  </h3>
                  <ul className="list-unstyled">
                    <li className="mt-7 gr-text-9 text-color-opacity">
                      <i
                        className="rounded-circle bg-opposite d-inline-block me-5"
                        css={`
                          width: 9px;
                          height: 9px;
                        `}
                      ></i>
                      Must have 5+ years' experience in delivering training in VMware eco-system for both on-prem and cloud related solutions from VMware, Microsoft Azure, Google Cloud Platform or similar.
                    </li>
                    <li className="mt-7 gr-text-9 text-color-opacity">
                      <i
                        className="rounded-circle bg-opposite d-inline-block me-5"
                        css={`
                          width: 9px;
                          height: 9px;
                        `}
                      ></i>
                      Must be able to develop the training infrastructure which includes installation, configuration and implementation of VMware vSphere, NSX, vRA, and vROPS.
                    </li>
                    <li className="mt-7 gr-text-9 text-color-opacity">
                      <i
                        className="rounded-circle bg-opposite d-inline-block me-5"
                        css={`
                          width: 9px;
                          height: 9px;
                        `}
                      ></i>
                      Experience installing in client's environments and transferring technology to clients would be an added plus.
                    </li>
                    <li className="mt-7 gr-text-9 text-color-opacity">
                      <i
                        className="rounded-circle bg-opposite d-inline-block me-5"
                        css={`
                          width: 9px;
                          height: 9px;
                        `}
                      ></i>
                      Must be able to deliver training on new services added to the ecosystem including the existing VMWare ESX, vCenter Server, VMware HA, DRS, vMotion, svMotion, Host profiles, Networks and Datastores with LDAP, Kerberos, Active Directory, LDAP, Docker, Kubernetes, Linux, and Ansible.
                    </li>
                    <li className="mt-7 gr-text-9 text-color-opacity">
                      <i
                        className="rounded-circle bg-opposite d-inline-block me-5"
                        css={`
                          width: 9px;
                          height: 9px;
                        `}
                      ></i>
                      Improve their job skills and to quickly create value to our existing clientele.
                    </li>
                    <li className="mt-7 gr-text-9 text-color-opacity">
                      <i
                        className="rounded-circle bg-opposite d-inline-block me-5"
                        css={`
                          width: 9px;
                          height: 9px;
                        `}
                      ></i>
                      Develop simulations of solutions in various VMware platforms for educational purposes, build team exercises, conduct and lead group discussions with vendors and employees, publish working session webinars for later consumption.
                    </li>
                    <li className="mt-7 gr-text-9 text-color-opacity">
                      <i
                        className="rounded-circle bg-opposite d-inline-block me-5"
                        css={`
                          width: 9px;
                          height: 9px;
                        `}
                      ></i>
                      Work with vendors and internal leadership to manage the costs involved for training infrastructure and ongoing costs to ensure profitability.
                    </li>
                    <li className="mt-7 gr-text-9 text-color-opacity">
                      <i
                        className="rounded-circle bg-opposite d-inline-block me-5"
                        css={`
                          width: 9px;
                          height: 9px;
                        `}
                      ></i>
                      Need to make sure the servers are consistent across environments to aid in troubleshooting so that we have consistent results for every training participant.
                    </li>
                    <li className="mt-7 gr-text-9 text-color-opacity">
                      <i
                        className="rounded-circle bg-opposite d-inline-block me-5"
                        css={`
                          width: 9px;
                          height: 9px;
                        `}
                      ></i>
                      Responsible for constructing and providing Standard Operating procedure documents (SOP's) that can be consumed.
                    </li>
                    <li className="mt-7 gr-text-9 text-color-opacity">
                      <i
                        className="rounded-circle bg-opposite d-inline-block me-5"
                        css={`
                          width: 9px;
                          height: 9px;
                        `}
                      ></i>
                      Works out of the box and develops creative enablement solutions with current VMware products and their capabilities to solve business and functional needs.
                    </li>
                    <li className="mt-7 gr-text-9 text-color-opacity">
                      <i
                        className="rounded-circle bg-opposite d-inline-block me-5"
                        css={`
                          width: 9px;
                          height: 9px;
                        `}
                      ></i>
                      Involves in Daily support activities related to End User issues, Technical issues etc. Utilizes acquired knowledge to mentor on best practices in engineered solutions and to maintain solid analytics infrastructure as per VMware Solution directives.
                    </li>
                    <li className="mt-7 gr-text-9 text-color-opacity">
                      <i
                        className="rounded-circle bg-opposite d-inline-block me-5"
                        css={`
                          width: 9px;
                          height: 9px;
                        `}
                      ></i>
                      Perform other duties as assigned.
                    </li>
                  </ul>
                </div>
                <div className="single-block mb-12 mb-lg-15">
                  <h3 className="gr-text-6 heading-color fw-bold mb-9">
                    How to apply
                  </h3>
                  <p className="gr-text-9 text-color-opacity mb-9">
                    We want to hire smart, curious and ambitious folks so please reach out even if you do not have all of the requisite experience. We are looking for engineers with the potential to grow!
                  </p>
                  <a href="mailto:info@ainintel.com" className="btn btn-primary mt-3 mt-lg-10">
                    Apply for this job
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </PageWrapper>
    </>
  );
};
export default JobOpenings;
